import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadPs5 = () => {
  const promisePs5 = new Promise((resolve, reject) => {
    loader.load(
      "office/model/ps5.gltf",
      function (ps5) {
        scene.add(ps5.scene);

        const ps5Mesh = ps5.scene;

        var material = new THREE.MeshBasicMaterial({ color: 0xff0000 });

        ps5Mesh.material = material;

        ps5Mesh.position.set(
          -7.258377563797656,
          0.7349356721730764,
          8.445026147270587
        );
        ps5Mesh.rotation.set(
          -3.141592653589793,
          1.5388905414156575,
          3.141592653589793
        );

        ps5Mesh.scale.set(0.04, 0.04, 0.04);

        //ControlFunction(ps5Mesh);
        resolve(ps5Mesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promisePs5;
};

export { loadPs5 };
