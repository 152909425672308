import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const pngCodeWall5 = texture_loader.load("./office/textures/codeWall10.jpg");
import { progressbar, modelsLoaded } from "./progressbar";

const loadCodeWall5 = () => {
  const promiseCodeWall5 = new Promise((resolve, reject) => {
    const geometryCodeWall5 = new THREE.PlaneGeometry(1, 1);
    const materialCodeWall5 = new THREE.MeshBasicMaterial({
      map: pngCodeWall5,
      transparent: true,
    });
    const codeWall5 = new THREE.Mesh(geometryCodeWall5, materialCodeWall5);

    codeWall5.scale.x = 0.8;
    codeWall5.scale.y = 1;

    codeWall5.position.set(
      2.49230432074088,
      2.1085744240833217,
      10.129746742325816
    );

    codeWall5.rotateY(Math.PI);

    scene.add(codeWall5);
    //ControlFunction(codeWall5);

    resolve(codeWall5);
    progressbar();
  });

  return promiseCodeWall5;
};

export { loadCodeWall5 };
