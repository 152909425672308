import * as THREE from "three";
import { progressbar } from "./progressbar";
import { scene } from "./scene";

const loadNeon = () => {
  const promiseNeon = new Promise((resolve, reject) => {
    var planeGeometry = new THREE.BoxGeometry(1, 1, 1);
    var neonMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      emissive: 0xffffff,
      emissiveIntensity: 1,
      roughness: 0,
      metalness: 0,
    });

    var neon = new THREE.Mesh(planeGeometry, neonMaterial);
    neon.position.set(
      -0.0262828220082727,
      3.720264200604586,
      6.100922838221812
    );

    neon.castShadow = false;
    neon.receiveShadow = false;

    neon.scale.set(4.6, 0.05, 0.5);

    scene.add(neon);
    //ControlFunction(neon);
    resolve(neon);
    progressbar();
  });
  return promiseNeon;
};

export { loadNeon };
