import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadTapisRoulant = () => {
  const promiseTapisRoulant = new Promise((resolve, reject) => {
    loader.load(
      "office/model/tapis-roulant.gltf",
      function (tapisRoulant) {
        scene.add(tapisRoulant.scene);
        const tapisRoulantMesh = tapisRoulant.scene;

        tapisRoulantMesh.position.set(
          6.222733510579022,
          0.6123739518014393,
          4.040391287957249
        );
        tapisRoulantMesh.rotation.set(
          0.016873125295305543,
          0.010384615684759478,
          -0.02066246635795013
        );

        tapisRoulantMesh.scale.set(0.012, 0.012, 0.012);

        tapisRoulantMesh.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = false;
          }
        });


        //ControlFunction(tapisRoulantMesh);
        resolve(tapisRoulantMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseTapisRoulant;
};

export { loadTapisRoulant };
