import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadPendrive = () => {
  const promisePendrive = new Promise((resolve, reject) => {
    loader.load(
      "office/model/pendrive.gltf",
      function (pendrive) {
        scene.add(pendrive.scene);
        const pendriveMesh = pendrive.scene;
        pendriveMesh.position.set(0, 0, 0);
        pendriveMesh.position.set(
          0.3299488804680888,
          1.585578240222518,
          4.168595409525276
        );
        pendriveMesh.rotation.set(
          -0.21301216793362024,
          1.2169919780975134,
          0.20116920223041412
        );

        //ControlFunction(pendriveMesh);
        resolve(pendrive);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promisePendrive;
};

export { loadPendrive };
