import * as THREE from "three";
import { scene } from "./scene";
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const texture = texture_loader.load("./office/textures/paint2.jpg");
import { progressbar,modelsLoaded } from "./progressbar";

const loadPaint2 = () => {
  const promisePaint2 = new Promise((resolve, reject) => {
    const materialFront = new THREE.MeshBasicMaterial({ map: texture });
    const materialBlack = new THREE.MeshBasicMaterial({ color: 0xffffff });
    const materialOther = new THREE.MeshBasicMaterial({ color: 0xffffff });

    const cubeGeometry = new THREE.BoxGeometry(1, 1, 0.4);
    const paint = new THREE.Mesh(cubeGeometry, [
      materialOther,
      materialBlack,
      materialOther,
      materialOther,
      materialFront,
      materialOther,
    ]);

    paint.scale.x = 1.3;
    paint.scale.y = 0.9;
    paint.scale.z = 0.1;

    paint.position.set(7.536233216720894,2.62601544601118,5.707184431572649);
    paint.rotation.set(0,0,0); 
    const quaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, -Math.PI / 2, 0));
    paint.applyQuaternion(quaternion);

    scene.add(paint);
    //ControlFunction(paint);
    resolve(paint);
    progressbar();
  });
  return promisePaint2;
};

export { loadPaint2 };
