import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { progressbar, modelsLoaded } from "./progressbar";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
let currentTexture = 1;

const loadScreen = () => {
  const promiseScreen = new Promise((resolve, reject) => {
    const geometry = new THREE.BoxGeometry();
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const cube = new THREE.Mesh(geometry, material);
    const screenTexture1 = texture_loader.load("./office/textures/screen1.jpg");
    const screenTexture2 = texture_loader.load("./office/textures/screen2.jpg");
    const screenTexture3 = texture_loader.load("./office/textures/screen3.jpg");
    //const screenTexture4 = texture_loader.load("./office/textures/screen4.jpg");
    const materialScreen1 = new THREE.MeshBasicMaterial({
      map: screenTexture1,
    });
    const materialScreen2 = new THREE.MeshBasicMaterial({
      map: screenTexture2,
    });
    const materialScreen3 = new THREE.MeshBasicMaterial({
      map: screenTexture3,
    });
    cube.material = materialScreen1;
    cube.position.set(
      0.007717832691007509,
      1.9374315653549687,
      4.160137670411126
    );

    cube.scale.x = 0.88;
    cube.scale.y = 0.46;
    cube.scale.z = 0.0001;
    cube.rotation.x = -0.21968025377231032;
    cube.rotation.y = 0.2119560882520583;
    cube.rotation.z = 0.05019129840054355;
    scene.add(cube);
    //ControlFunction(cube);
    resolve(cube);
    progressbar();

    function changeTexture() {
      if (currentTexture == 1) {
        cube.material = materialScreen2;
        currentTexture = 2;
      } else if (currentTexture == 2) {
        cube.material = materialScreen3;
        currentTexture = 3;
      } else if (currentTexture == 3) {
        cube.material = materialScreen1;
        currentTexture = 1;
      }
    }

    setInterval(changeTexture, 5000);
  });
  return promiseScreen;
};

export { loadScreen };
