import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
import { progressbar } from "./progressbar";
import { scene } from "./scene";
const loader = new GLTFLoader();

const loadJava = () => {
  const promiseJava = new Promise((resolve, reject) => {
    loader.load(
      "office/model/java.gltf",
      function (logo) {
        scene.add(logo.scene);

        const logoMesh = logo.scene;

        const logoSx = logoMesh.children[0];
        const logoCenter = logoMesh.children[1];
        const logoDx = logoMesh.children[3];
        /*
        logoSx.material.color.set(0xff0000);
        logoCenter.material.color.set(0x333);
        logoDx.material.color.set(0x336699);
        */
        logoMesh.scale.x = 2.2;
        logoMesh.scale.y = 2.2;
        logoMesh.scale.z = 2.2;

        logoMesh.position.set(
          4.014979144623658,
          1.9352967742986524,
          9.614784880553326
        );

        logoMesh.rotation.set(
          1.581457158041909,
          -0.00040016120876790895,
          3.104076040061552
        );

        //ControlFunction(logoMesh);
        resolve(logo);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseJava;
};

export { loadJava };
