import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const pngCodeWall6 = texture_loader.load("./office/textures/codeWall7.jpg");
import { progressbar, modelsLoaded } from "./progressbar";

const loadCodeWall6 = () => {
  const promiseCodeWall6 = new Promise((resolve, reject) => {
    const geometryCodeWall6 = new THREE.PlaneGeometry(1, 1);
    const materialCodeWall6 = new THREE.MeshBasicMaterial({
      map: pngCodeWall6,
      transparent: true,
    });
    const codeWall6 = new THREE.Mesh(geometryCodeWall6, materialCodeWall6);

    codeWall6.scale.x = 0.8;
    codeWall6.scale.y = 1;

    codeWall6.position.set(
      1.5395930673718201,
      2.1085744240833217,
      10.129746742325816
    );

    codeWall6.rotateY(Math.PI);

    scene.add(codeWall6);
    //ControlFunction(codeWall6);

    resolve(codeWall6);
    progressbar();
  });

  return promiseCodeWall6;
};

export { loadCodeWall6 };
