import * as THREE from "three";
import { scene } from "./scene";
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
import { progressbar, modelsLoaded } from "./progressbar";
const roofTexture = texture_loader.load("./office/textures/roof.jpg");

const loadRoof = () => {
  const promiseRoof = new Promise((resolve, reject) => {
    const RoofGeometry = new THREE.PlaneGeometry(2, 2);
    roofTexture.wrapS = THREE.RepeatWrapping;
    roofTexture.wrapT = THREE.RepeatWrapping;
    roofTexture.magFilter = THREE.LinearFilter;
    roofTexture.minFilter = THREE.LinearMipmapLinearFilter;
    roofTexture.repeat.set(1, 1);

    const materialRoofGeometry = new THREE.MeshBasicMaterial({
      map: roofTexture,
    });
    const roof = new THREE.Mesh(
      RoofGeometry,
      materialRoofGeometry
    );
    roof.material = materialRoofGeometry;
    
    roof.position.set(-0.28021639809844645,3.958906930779716,6.477602560701504);
    roof.scale.x = 7.8;
    roof.scale.y = 3.6;
    roof.rotation.x = Math.PI / 2;
    //roof.castShadow = true;

    scene.add(roof);
    //ControlFunction(roof);
    resolve(roof);
    progressbar();
  });
  return promiseRoof;
};

export { loadRoof };
