import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadSeriesX = () => {
  const promiseSeriesX = new Promise((resolve, reject) => {
    loader.load(
      "office/model/seriesx.gltf",
      function (SeriesX) {
        scene.add(SeriesX.scene);

        const SeriesXMesh = SeriesX.scene;

        SeriesXMesh.position.set(
          -7.2543359925307405,
          0.7008816990212077,
          7.2398983635360254
        );
        SeriesXMesh.rotation.set(
          -1.2407224962266086,
          1.5410812987300704,
          -0.3105306120850695
        );

        SeriesXMesh.scale.set(0.017, 0.017, 0.017);

        //ControlFunction(SeriesXMesh);
        resolve(SeriesXMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseSeriesX;
};

export { loadSeriesX };
