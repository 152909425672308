import * as THREE from "three";
const canvas = document.querySelector("canvas.webgl");

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

let antialiasing = true;
let shadow = true;

if (isMobile) {
  antialiasing = false;
  shadow = false;
}

const antialiasingValue = localStorage.getItem("antialiasing");
if (antialiasingValue !== null) {
  if (antialiasingValue === "true") {
    antialiasing = true;
  } else {
    antialiasing = false;
  }
}

const shadowValue = localStorage.getItem("shadow");
if (shadowValue !== null) {
  if (shadowValue === "true") {
    shadow = true;
  } else {
    shadow = false;
  }
}

if (isMobile) {
  antialiasing = false;
  shadow = false;
}

let renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: antialiasing,
});
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(window.devicePixelRatio);

if (shadow) {
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
}

export { renderer };
