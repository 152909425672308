import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadSwitch = () => {
  const promiseSwitch = new Promise((resolve, reject) => {
    loader.load(
      "office/model/switch.gltf",
      function (switchNintendo) {
        scene.add(switchNintendo.scene);

        const switchMesh = switchNintendo.scene;

        var material = new THREE.MeshBasicMaterial({ color: 0xff0000 });

        switchMesh.material = material;

        switchMesh.position.set(
          -7.258377563797656,
          1.271886440429226,
          6.207571357662292
        );
        switchMesh.rotation.set(
          -0.03141022567152577,
          -1.1405444925592525,
          3.114655615605736
        );

        switchMesh.scale.set(0.025, 0.025, 0.025);

        //ControlFunction(switchMesh);
        resolve(switchMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseSwitch;
};

export { loadSwitch };
