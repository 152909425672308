import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadOffice = () => {
  const promiseOffice = new Promise((resolve, reject) => {
    loader.load(
      "office/model/office3.gltf",
      function (office) {
        scene.add(office.scene);
        office.scene.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = false;
          }
        });

        resolve(office);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseOffice;
};

export { loadOffice };
