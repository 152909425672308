import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadPs4 = () => {
  const promisePs4 = new Promise((resolve, reject) => {
    loader.load(
      "office/model/ps4.gltf",
      function (ps4) {
        scene.add(ps4.scene);

        const ps4Mesh = ps4.scene;

        var material = new THREE.MeshBasicMaterial({ color: 0xff0000 });

        ps4Mesh.material = material;

        ps4Mesh.position.set(
          -8.393666676276636,0.5631342516889746,7.702127747749812
        );
        ps4Mesh.rotation.set(
          -3.141592653589793,
          1.5388905414156575,
          3.141592653589793
        );

        ps4Mesh.scale.set(0.06, 0.06, 0.06);

        //ControlFunction(ps4Mesh);
        resolve(ps4Mesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promisePs4;
};

export { loadPs4 };
