import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadJoypad3b = () => {
  const promiseJoypad3b = new Promise((resolve, reject) => {
    loader.load(
      "office/model/joypad3.gltf",
      function (joypad3b) {
        scene.add(joypad3b.scene);

        const joypad3bMesh = joypad3b.scene;

        joypad3bMesh.position.set(0, 0, 0);
        joypad3bMesh.rotation.x = 0;
        joypad3bMesh.rotation.z = 0;
        joypad3bMesh.position.x = 0;

        joypad3bMesh.position.set(
          -7.4092279879994125,
          1.5593410393353664,
          8.091767085988904
        );
        joypad3bMesh.rotation.set(
          -2.5295186532805327,
          0.4027528541795947,
          1.3285356932200938
        );

        joypad3bMesh.scale.set(2.7, 2.7, 2.7);

        //ControlFunction(joypad3bMesh);
        resolve(joypad3bMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseJoypad3b;
};

export { loadJoypad3b };
