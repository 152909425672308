import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadJoypad2 = () => {
  const promiseJoypad2 = new Promise((resolve, reject) => {
    loader.load(
      "office/model/joypad3.gltf",
      function (joypad2) {
        scene.add(joypad2.scene);

        const joypad2Mesh = joypad2.scene;

        joypad2Mesh.position.set(0, 0, 0);
        joypad2Mesh.rotation.x = 0;
        joypad2Mesh.rotation.z = 0;
        joypad2Mesh.position.x = 0;

        joypad2Mesh.position.set(
          -7.236287932523401,1.5664469683903257,8.627205727089422
        );
        joypad2Mesh.rotation.set(
          -2.5267058179920165,-0.044196693786237924,1.637885324390111
        );

        joypad2Mesh.scale.set(2.7, 2.7, 2.7);

        //ControlFunction(joypad2Mesh);
        resolve(joypad2Mesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseJoypad2;
};

export { loadJoypad2 };
