import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const pngCodeWall1 = texture_loader.load("./office/textures/codeWall1.jpg");
import { progressbar, modelsLoaded } from "./progressbar";

const loadCodeWall1 = () => {
  const promiseCodeWall1 = new Promise((resolve, reject) => {
    const geometryCodeWall1 = new THREE.PlaneGeometry(1, 1);
    const materialCodeWall1 = new THREE.MeshBasicMaterial({
      map: pngCodeWall1,
      transparent: true,
    });
    const codeWall1 = new THREE.Mesh(geometryCodeWall1, materialCodeWall1);

    codeWall1.scale.x = 1;
    codeWall1.scale.y = 0.68;

    codeWall1.position.set(
      -2.0941517863115743,2.5318693298521455,2.9581359829386775
    );
    scene.add(codeWall1);
    //ControlFunction(codeWall1);
    resolve(codeWall1);
    progressbar();
  });

  return promiseCodeWall1;
};

export { loadCodeWall1 };
