import * as THREE from "three";
import { scene } from "./scene";
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
import { progressbar, modelsLoaded } from "./progressbar";

const loadGlass = () => {
  const promiseGlass = new Promise((resolve, reject) => {
    const planeGeometry = new THREE.PlaneGeometry(2, 2);
    const planeMaterial = new THREE.MeshBasicMaterial({ color: 0x999999 });
    const glass = new THREE.Mesh(planeGeometry, planeMaterial);

    const material = new THREE.MeshPhysicalMaterial({
      metalness: 0,
      roughness: 0.6,
      thickness: 0.1,
      transmission: 0.9,
      color: 0x1f6286,
      transparent: true,
      opacity: 0.82,
    });
    /*
         metalness: 0,
      roughness: 0.6,
      thickness: 0.1,
      transmission: 0.9,
      color: 0x9e9e9e,
      transparent: true,
      opacity: 0.85,
    */
    glass.material = material;

    glass.scale.x = 7.5;
    glass.scale.y = 4;

    glass.position.set(0, 0.16420122961882933, 2.771223491203161);

    scene.add(glass);
    //ControlFunction(glass);
    resolve(glass);
    progressbar();
  });
  return promiseGlass;
};

export { loadGlass };
