import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
import { progressbar,modelsLoaded } from "./progressbar";
const loader = new GLTFLoader();

const loadCity = () => {
  const promiseCity = new Promise((resolve, reject) => {
    loader.load(
      "office/model/city.gltf",
      function (city) {
        scene.add(city.scene);

        const cityMesh = city.scene;

        cityMesh.position.set(0, 0, 0);
        cityMesh.rotation.x = 0;
        cityMesh.rotation.z = 0;
        cityMesh.position.x = 0;

        cityMesh.scale.x = 0.4;
        cityMesh.scale.y = 0.4;
        cityMesh.scale.z = 0.4;

        var geometry = new THREE.BoxGeometry(1, 1, 1);
        var material = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          transparent: true,
          opacity: 0,
        });
        var cityContainer = new THREE.Mesh(geometry, material);
        scene.add(cityContainer);
        cityContainer.add(cityMesh);
        cityMesh.position.set(0, 0, 0);

        cityContainer.position.set(
          -5.683312355438044,
          0.8841267846642755,
          -7.366092183916542
        );
        cityContainer.rotation.set(
          0.016873760304267522,
          0.013531323544830654,
          -0.020715570967460965
        );

        //ControlFunction(cityContainer);
        resolve(city);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseCity;
};

export { loadCity };
