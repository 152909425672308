import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
let texture_loader = new THREE.TextureLoader();
import { progressbar, modelsLoaded } from "./progressbar";
const loader = new GLTFLoader();

const loadCoffee = () => {
  const promiseCoffee = new Promise((resolve, reject) => {
    loader.load(
      "office/model/coffee.gltf",
      function (coffee) {
        scene.add(coffee.scene);

        const coffeeMesh = coffee.scene;

        coffeeMesh.rotation.x = 0;
        coffeeMesh.rotation.z = 0;
        coffeeMesh.position.x = 0;

        coffeeMesh.scale.x = 0.05;
        coffeeMesh.scale.y = 0.05;
        coffeeMesh.scale.z = 0.05;

        coffeeMesh.traverse(function (child) {
          if (child.isMesh) {
            const coffeeMaterial = new THREE.MeshStandardMaterial({
              color: 0xc1c1c1,
            });
            child.material = coffeeMaterial;
          }
        });

        coffeeMesh.position.set(
          -0.32305129202126176,
          1.5711286614541249,
          4.287566162403142
        );
        coffeeMesh.rotation.set(
          0.02823356919899188,
          -0.9302355031612173,
          0.0021514829298491384
        );

        //ControlFunction(coffeeMesh);

        var radiusTop = 1;
        var radiusBottom = 1;
        var height = 1;
        var radialSegments = 16;
        var heightSegments = 1;
        var openEnded = false;

        var geometryCI = new THREE.CylinderGeometry(
          radiusTop,
          radiusBottom,
          height,
          radialSegments,
          heightSegments,
          openEnded
        );

        const materialCI = new THREE.MeshBasicMaterial({ color: 0x654321 });

        var coffeeInt = new THREE.Mesh(geometryCI, materialCI);
        coffeeInt.position.set(
          -0.3150462724315357,
          1.5900524952667,
          4.297561081329463
        );
        coffeeInt.scale.set(0.04, 0.04, 0.04);
        scene.add(coffeeInt);
        //ControlFunction(coffeeInt);

        resolve(coffee);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseCoffee;
};

export { loadCoffee };
