import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadStand = () => {
  const promiseStand = new Promise((resolve, reject) => {
    loader.load(
      "office/model/stand.gltf",
      function (stand) {
        scene.add(stand.scene);

        const standMesh = stand.scene;

        standMesh.position.set(
          3.8408449983164346,1.8952484016964068,9.956841588554358
        );

        standMesh.rotation.set(
          -3.1233229765789563,0.03132073860337906,3.1356390905168974
        );

        standMesh.scale.set(1.5, 1.5, 1.5);
        //ControlFunction(standMesh);
        resolve(standMesh);




        const standMesh2 = standMesh.clone();
        scene.add(standMesh2);
        standMesh2.position.set(
          4.665441145511653,1.8952484016964068,9.956841588554358
        );
        standMesh2.rotation.set(
          -3.1233319342397943,0.0006430551652116678,3.1361995363892126
        );
        standMesh2.scale.set(1.5, 1.5, 1.5);
        //ControlFunction(standMesh2);



        const standMesh3 = standMesh.clone();
        scene.add(standMesh3);
        standMesh3.position.set(
          5.471998851230523,1.8952484016964068,9.956841588554358
        );
        standMesh3.rotation.set(
          -3.12333193714055,0.00030959790753696723,3.136205626235698
        );
        standMesh3.scale.set(1.5, 1.5, 1.5);
        //ControlFunction(standMesh3);


     
        const standMesh4 = standMesh.clone();
        scene.add(standMesh4);
        standMesh4.position.set(
          5.4947889658848394,0.5911499225464351,9.956841588554358
        );

        standMesh4.rotation.set(
          -3.1233229765789563,0.03132073860337906,3.1356390905168974
        );

        standMesh4.scale.set(1.5, 1.5, 1.5);
        //ControlFunction(standMesh4);



        const standMesh5 = standMesh.clone();
        scene.add(standMesh5);
        standMesh5.position.set(
          4.682790853171026,0.5911499225464351,9.956841588554358
        );

        standMesh5.rotation.set(
          -3.1233229765789563,0.03132073860337906,3.1356390905168974
        );

        standMesh5.scale.set(1.5, 1.5, 1.5);
        //ControlFunction(standMesh5);


        const standMesh6 = standMesh.clone();
        scene.add(standMesh6);
        standMesh6.position.set(
          3.882469905113659,0.5911499225464351,9.956841588554358
        );

        standMesh6.rotation.set(
          -3.1233229765789563,0.03132073860337906,3.1356390905168974
        );

        standMesh6.scale.set(1.5, 1.5, 1.5);
        //ControlFunction(standMesh6);



        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseStand;
};

export { loadStand };
