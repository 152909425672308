import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadShelf = () => {
  const promiseShelf = new Promise((resolve, reject) => {
    loader.load(
      "office/model/shelf.gltf",
      function (shelf) {
        scene.add(shelf.scene);
        const shelfMesh = shelf.scene;
        var materialShelf = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        shelfMesh.material = materialShelf;
        shelfMesh.position.set(
          4.982520393415158,
          0.7119307916276034,
          11.320853462765513
        );
        //var rotationAngle = Math.PI / 2;
        //shelfMesh.rotation.set(0, rotationAngle, 0);
        var quaternion = new THREE.Quaternion();
        quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI);
        shelfMesh.quaternion.copy(quaternion);
        shelfMesh.scale.set(0.14, 0.14, 0.14);
        //ControlFunction(shelfMesh);

        shelfMesh.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = false;
            object.receiveShadow = false;
          }
        });
        resolve(shelfMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseShelf;
};

export { loadShelf };
