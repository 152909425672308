import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const png = texture_loader.load("./office/textures/logodb.png");
import { progressbar,modelsLoaded } from "./progressbar";

const loadDbLogoPng = () => {
  const promiseDbPng = new Promise((resolve, reject) => {
    var planeGeometry = new THREE.PlaneGeometry(1, 1);
    var planeMaterial = new THREE.MeshBasicMaterial({ color: 0x999999 });
    var mesh = new THREE.Mesh(planeGeometry, planeMaterial);

    const material = new THREE.MeshBasicMaterial({
      map: png,
      transparent: true,
    });

    mesh.material = material;

    mesh.scale.x = 0.45;
    mesh.scale.y = 0.15;

    mesh.position.set( -1.371655774175157,1.069550455069972,4.725517169668114);
    mesh.rotation.set(0,0.36113717812359614,0);

    scene.add(mesh);
    //ControlFunction(mesh);
    resolve(mesh);
    progressbar();
  });

  return promiseDbPng;
};

export { loadDbLogoPng };
