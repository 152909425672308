import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadNodeLogo = () => {
  const promiseNodeLogo = new Promise((resolve, reject) => {
    loader.load(
      "office/model/nodelogo.gltf",
      function (stand) {
        scene.add(stand.scene);

        const NodeLogoMesh = stand.scene;

        NodeLogoMesh.position.set(
          5.6921342620884054,2.028527210291136,9.87354248969015
        );

        NodeLogoMesh.rotation.set(
          1.496115546819817,0.03132073860337906,3.1356390905168974
        );

        NodeLogoMesh.scale.set(1.4, 1.4, 1.4);
        //ControlFunction(NodeLogoMesh);
        resolve(NodeLogoMesh);

        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseNodeLogo;
};

export { loadNodeLogo };
