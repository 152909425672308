import * as THREE from "three";
import { scene } from "./scene";
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const skyTexture1 = texture_loader.load("./office/textures/sky.jpg");
import { progressbar,modelsLoaded } from "./progressbar";

const loadSky = () => {
  const promiseSky = new Promise((resolve, reject) => {
    var planeGeometry = new THREE.PlaneGeometry(2, 2);
    var planeMaterial = new THREE.MeshBasicMaterial({ color: 0x999999 });
    var sky = new THREE.Mesh(planeGeometry, planeMaterial);

    const materialSKy = new THREE.MeshBasicMaterial({
      map: skyTexture1,
    });

    sky.material = materialSKy;

    sky.scale.x = 70;
    sky.scale.y = 40;

    sky.position.set(0, 0, -30.969106486708313);

    scene.add(sky);
    //ControlFunction(sky);
    resolve(sky);
    progressbar();
  });
  return promiseSky;
};

export { loadSky };
