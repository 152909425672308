import * as THREE from "three";
import { scene } from "./scene";
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
import { progressbar, modelsLoaded } from "./progressbar";
const wall = texture_loader.load("./office/textures/wall4.jpg");

const loadBackWall = () => {
  const promiseBackWall = new Promise((resolve, reject) => {
    const backWallGeometry = new THREE.PlaneGeometry(2, 2);
    wall.wrapS = THREE.RepeatWrapping;
    wall.wrapT = THREE.RepeatWrapping;
    wall.magFilter = THREE.LinearFilter;
    wall.minFilter = THREE.LinearMipmapLinearFilter;
    wall.repeat.set(1, 2);
    wall.castShadow = true;
    wall.receiveShadow = true;

    const materialBackWallGeometry = new THREE.MeshBasicMaterial({
      map: wall,
    });
    const backWall = new THREE.Mesh(
      backWallGeometry,
      materialBackWallGeometry
    );
    backWall.material = materialBackWallGeometry;

    backWall.scale.x = 7.8;
    backWall.scale.y = 3.5;
    backWall.rotation.y += Math.PI;
    backWall.position.set(0.0245006344460188,0.48957027332625147,10.130155866010531);

    scene.add(backWall);
    //ControlFunction(backWall);
    resolve(backWall);
    progressbar();
  });
  return promiseBackWall;
};

export { loadBackWall };
