import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { progressbar, modelsLoaded } from "./progressbar";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
let monitor_screenMesh = "";
let texture_loader = new THREE.TextureLoader();
let currentTexture = 1;

const loadScreenNotebook = () => {
  const promiseScreenNotebook = new Promise((resolve, reject) => {
    const geometryScreenNotebook = new THREE.BoxGeometry();
    const materialScreenNotebook = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
    });
    const screenNotebook = new THREE.Mesh(
      geometryScreenNotebook,
      materialScreenNotebook
    );
    const screenTextureNotebook1 = texture_loader.load(
      "./office/textures/screen4.jpg"
    );
    const screenTextureNotebook2 = texture_loader.load(
      "./office/textures/screen2.jpg"
    );
    const screenTextureNotebook3 = texture_loader.load(
      "./office/textures/screen3.jpg"
    );
    const materialScreenNotebook1 = new THREE.MeshBasicMaterial({
      map: screenTextureNotebook1,
    });
    const materialScreenNotebook2 = new THREE.MeshBasicMaterial({
      map: screenTextureNotebook2,
    });
    const materialScreenNotebook3 = new THREE.MeshBasicMaterial({
      map: screenTextureNotebook3,
    });

    screenNotebook.material = materialScreenNotebook1;

    screenNotebook.position.set(
      -0.8440924794956133,1.7903651155784015,4.085577546325296
    );

    screenNotebook.rotation.set(
      -0.28168576789041083,0.8269244038953256,0.23944259825490877
    );


    screenNotebook.scale.x = 0.50;
    screenNotebook.scale.y = 0.34;
    screenNotebook.scale.z = 0.0001;

    scene.add(screenNotebook);

    //ControlFunction(screenNotebook);

    resolve(screenNotebook);
    progressbar();
  });
  return promiseScreenNotebook;
};

export { loadScreenNotebook };
