import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const pngWorld = texture_loader.load("./office/textures/world.png");
import { progressbar, modelsLoaded } from "./progressbar";

const loadWorld = () => {
  const promiseWorld = new Promise((resolve, reject) => {
    var geometryWorld = new THREE.PlaneGeometry(1, 1);
    var materialWorld = new THREE.MeshBasicMaterial({ color: 0x999999 });
    var world = new THREE.Mesh(geometryWorld, materialWorld);
    const material = new THREE.MeshBasicMaterial({
      map: pngWorld,
      transparent: true,
    });
    world.material = material;
    world.scale.set(4,4,4);
    world.position.set(
      -3.9725994407291862,2.167614774344125,10.122201205951603
    );
    world.rotateY(Math.PI);

    scene.add(world);
    //ControlFunction(world);
    resolve(world);
    progressbar();
  });

  return promiseWorld;
};

export { loadWorld };
