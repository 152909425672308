import { modelsTotal } from "./config";
let modelsLoaded = 0;
let perc = 0;

const progressbar = () => {
  modelsLoaded++;
  //console.log(modelsLoaded);
  perc = (modelsLoaded / modelsTotal) * 100;
  document.getElementById("progress-bar").style.width = perc + "%";
  if (modelsTotal == modelsLoaded) {
    document.getElementById("loaderContainer").style.display = "none";
    const buttonCamera = parseInt(document.getElementById("buttonCamera").value);
    if (buttonCamera === 1) {
      const buttonCameraContainer = document.getElementById(
        "buttonCameraContainer"
      );
      const windowHeight = window.innerHeight;
      const divHeight = buttonCameraContainer.offsetHeight;
      const topPosition = (windowHeight - divHeight) / 2;
      buttonCameraContainer.style.top = topPosition + "px";
      setTimeout(function() {
        buttonCameraContainer.style.display = "block";
      }, 1000);
      
    }
  }
};

export { progressbar, modelsLoaded };
