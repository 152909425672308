import * as THREE from "three";
import { scene } from "./scene";
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
import { progressbar, modelsLoaded } from "./progressbar";
const wallSx = texture_loader.load("./office/textures/wall3.jpg");

const loadFrontWallSx = () => {
  const promiseFrontWallSx = new Promise((resolve, reject) => {
    const frontWallGeometrySx = new THREE.PlaneGeometry(2, 2);
    wallSx.wrapS = THREE.RepeatWrapping;
    wallSx.wrapT = THREE.RepeatWrapping;
    wallSx.magFilter = THREE.LinearFilter;
    wallSx.minFilter = THREE.LinearMipmapLinearFilter;
    wallSx.repeat.set(1, 2);
    wallSx.castShadow = true;
    wallSx.receiveShadow = true;

    const materialFrontWallGeometrySx = new THREE.MeshBasicMaterial({
      map: wallSx,
    });
    const frontWallSx = new THREE.Mesh(
      frontWallGeometrySx,
      materialFrontWallGeometrySx
    );
    frontWallSx.material = materialFrontWallGeometrySx;

    frontWallSx.scale.x = 0.5;
    frontWallSx.scale.y = 3.5;

    frontWallSx.position.set(
      -3.137869085867787,
      0.5689439284456813,
      3.3546925507013423
    );

    var quaternion = new THREE.Quaternion();
    quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2);

    // Applicazione della rotazione all'oggetto
    frontWallSx.rotation.setFromQuaternion(quaternion);

    scene.add(frontWallSx);
    //ControlFunction(frontWallSx);
    resolve(frontWallSx);
    progressbar();
  });
  return promiseFrontWallSx;
};

export { loadFrontWallSx };
