import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let elixMesh = new THREE.Object3D();
let pivotFan = new THREE.Object3D();
let texture_loader = new THREE.TextureLoader();
import { renderer } from "./renderer";
import { camera } from "./camera";
import { clock } from "./clock";
const pngReact = texture_loader.load("./office/textures/react.png");
let direction = 1;
import { progressbar, modelsLoaded } from "./progressbar";

const loadElix = () => {
  const promiseElix = new Promise((resolve, reject) => {
    loader.load(
      "office/model/elix.gltf",
      function (elix) {
        scene.add(elix.scene);
        elixMesh = elix.scene.children[0];

        elixMesh.scale.x = 0.015;
        elixMesh.scale.y = 0.015;
        elixMesh.scale.z = 0.015;
        elixMesh.position.set(1, 2, 5);

        pivotFan.position.copy(elixMesh.position);

        pivotFan.add(elixMesh);
        elixMesh.position.set(0, 0, 0);
        scene.add(pivotFan);
        pivotFan.position.set(
          -1.4666432695388794,
          1.6910057830810547,
          4.5213695793151855
        );

        pivotFan.rotation.y = 65;

        var rotSpeed = 0.25;

        var renderFan = function () {
          requestAnimationFrame(renderFan);
          elixMesh.rotation.x += rotSpeed;
          renderer.render(scene, camera);
        };
        renderFan();
        resolve(elix);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );

    loader.load(
      "office/model/fan3.gltf",

      function (fan3) {
        scene.add(fan3.scene);
        const fan3Mesh = fan3.scene.children[0];
        fan3Mesh.position.set(0.1, -0.346, 0);
        fan3Mesh.rotation.set(-1.6, 0, -0.7362);

        pivotFan.add(fan3Mesh);

        var rotSpeed = 0.012;

        const limit = 65;
        const limit2 = 66.3;
        const delta = clock.getDelta();
        const speed = 0.03;

        const renderBoxFan = function () {
          requestAnimationFrame(renderBoxFan);
          /*
          pivotFan.rotation.y += speed * delta * direction;
          if (pivotFan.rotation.y > limit2 || pivotFan.rotation.y < limit) {
            direction *= -1;
          }
          */

          //  renderer.render(scene, camera);
        };

        //react
        const materialReact = new THREE.MeshBasicMaterial({
          color: 0xe1e1e1,
          transparent: true,
          opacity: 0,
        });
        const geometryReact = new THREE.BoxGeometry(1, 1, 1);
        const React = new THREE.Mesh(geometryReact, materialReact);
        const geometryReactLogo = new THREE.PlaneGeometry(1, 1);
        const materialReactLogo = new THREE.MeshBasicMaterial({
          map: pngReact,
          transparent: true,
        });

        React.position.set(
          -1.2211302156512935,
          1.8547548708948054,
          4.42882556187408
        );

        React.rotation.set(
          -0.2847371030954095,
          0.7284443773854312,
          0.11304424241966497
        );

        const ReactLogo = new THREE.Mesh(geometryReactLogo, materialReactLogo);

        ReactLogo.scale.x = 0.12;
        ReactLogo.scale.y = 0.11;
        ReactLogo.position.set(0.5, 0, 0);
        ReactLogo.rotation.set(0, 0, 0);
        React.add(ReactLogo);
        scene.add(React);

        let rotationAngleReact = 0;
        const animateReact = function () {
          let deltaReact = clock.getDelta();
          rotationAngleReact -= 1.9 * deltaReact;
          ReactLogo.rotation.set(0, 0, rotationAngleReact);
          requestAnimationFrame(animateReact);
        };
        animateReact();

        renderBoxFan();
        resolve(fan3);
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseElix;
};

export { loadElix };
