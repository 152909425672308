import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar,modelsLoaded } from "./progressbar";

const loadCoinop = () => {
  const promiseCoinop = new Promise((resolve, reject) => {
    loader.load(
      "office/model/coinop.gltf",
      function (coinop) {
        scene.add(coinop.scene);

        const coinopMesh = coinop.scene;

        coinopMesh.position.set(0, 0, 0);
        coinopMesh.rotation.x = 0;
        coinopMesh.rotation.z = 0;
        coinopMesh.position.x = 0;

        coinopMesh.position.set(
          -6.994728422716973,0.5853162619335444,4.274844983946048
        );
        coinopMesh.rotation.set(
          0.010309662663425475,
          0.09194756968767076,
          0.04118339142438526);

          coinopMesh.scale.set(
          1,
          1,
          1,
        );

        coinopMesh.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = false;
          }
        });

        //ControlFunction(coinopMesh);
        resolve(coinopMesh);
        progressbar();

      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseCoinop;
};

export { loadCoinop };