import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar,modelsLoaded } from "./progressbar";

const loadTv = () => {
  const promiseTv = new Promise((resolve, reject) => {
    loader.load(
      "office/model/tv.gltf",
      function (tv) {
        scene.add(tv.scene);
        const tvMesh = tv.scene;
        tvMesh.position.set(
          -4.9601400816977055,0.5286116127658289,7.491074319688011
        );
        /*
        tvMesh.rotation.set(
          0.013533966149941658,-0.7097052093674563,0.05094921797161647);
       */

          tvMesh.scale.set(
          1.32,
          1.32,
          1.32
        );

        //ControlFunction(tvMesh);

        tvMesh.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = false;
          }
        });


        resolve(tvMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseTv;
};

export { loadTv };