import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadHtml5 = () => {
  const promiseHtml5 = new Promise((resolve, reject) => {
    loader.load(
      "office/model/html5.gltf",
      function (stand) {
        scene.add(stand.scene);

        const html5Mesh = stand.scene;

        html5Mesh.position.set(
          5.48609153316791,1.1275781859798677,9.920643343702258
        );

        html5Mesh.rotation.set(
          -3.1233229765789563,0.03132073860337906,3.1356390905168974
        );

        html5Mesh.scale.set(0.008, 0.008, 0.008);
        //ControlFunction(html5Mesh);
        resolve(html5Mesh);

        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseHtml5;
};

export { loadHtml5 };
