import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadJs = () => {
  const promiseJs = new Promise((resolve, reject) => {
    loader.load(
      "office/model/js.gltf",
      function (stand) {
        scene.add(stand.scene);

        const jsMesh = stand.scene;

        jsMesh.position.set(
          4.7340636960823534,0.7033723562713243,9.891009963142636
        );

        jsMesh.rotation.set(
          1.496115546819817,0.03132073860337906,3.1356390905168974
        );

        jsMesh.scale.set(4.2, 4.2, 4.2);
        //ControlFunction(jsMesh);
        resolve(jsMesh);

        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseJs;
};

export { loadJs };
