import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { progressbar } from "./progressbar";
import { scene } from "./scene";
const loader = new GLTFLoader();

const loadDbLogo = () => {
  const promiseDb = new Promise((resolve, reject) => {
    loader.load(
      "office/model/dblogo.gltf",
      function (logo) {
        scene.add(logo.scene);

        const logoMesh = logo.scene;

        //e0574f
        const logoSx = logoMesh.children[0];
        const logoCenter = logoMesh.children[1];
        const logoDx = logoMesh.children[3];
        /*
        logoSx.material.color.set(0xff0000);
        logoCenter.material.color.set(0x333);
        logoDx.material.color.set(0x336699);
        */
        logoMesh.scale.x = 23;
        logoMesh.scale.y = 23;
        logoMesh.scale.z = 23;

        logoMesh.position.set(
          5.562857703733601,
          2.5216157391682654,
          9.97577611453876
        );

        logoMesh.rotation.set(
          1.581457158041909,
          -0.00040016120876790895,
          3.104076040061552
        );

        // ControlFunction(logoMesh);
        resolve(logo);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseDb;
};

export { loadDbLogo };
