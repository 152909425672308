import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadKeyboard = () => {
  const promiseKeyboard = new Promise((resolve, reject) => {
    loader.load(
      "office/model/keyboard2.gltf",
      function (keyboard) {
        scene.add(keyboard.scene);
        const keyboardMesh = keyboard.scene;
        keyboardMesh.position.set(0, 0, 0);
        keyboardMesh.position.set(
          0.10640842595870126,
          1.7969298909574472,
          4.33316193008437
        );
        keyboardMesh.rotation.set(
          -0.0752923209016049,
          0.22971377250522346,
          0.018164786193657776
        );
        keyboardMesh.scale.set(0.8, 0.8, 0.8);
        //ControlFunction(keyboardMesh);
        resolve(keyboard);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseKeyboard;
};

export { loadKeyboard };
