import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadVr = () => {
  const promiseVr = new Promise((resolve, reject) => {
    loader.load(
      "office/model/vr.gltf",
      function (vr) {
        scene.add(vr.scene);

        const vrMesh = vr.scene;

        vrMesh.position.set(
          1.5628886914336413,2.0626003292766946,4.21682074281984
        );

        vrMesh.rotation.set(
          -0.07610334233303583,-1.3282607848860366,-0.07926555271507801
        );
       
        vrMesh.scale.set(3.6, 3.6, 3.6);
        //ControlFunction(vrMesh);
        resolve(vrMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseVr;
};

export { loadVr };
