import * as THREE from "three";
import { scene } from "./scene";
import { camera } from "./camera";
import { renderer } from "./renderer";

let cameraPosition = camera.position;
let cameraRotation = camera.rotation;

function keysCamera() {
  document.addEventListener("keydown", function (event) {
    //console.log(event.keyCode);
    switch (event.keyCode) {
      case 37:
        //arrow sx
        cameraPosition.x -= 0.1;
        break;
      case 38:
        //arrow up
        cameraPosition.z -= 0.1;
        break;
      case 39:
        //arrow sx
        cameraPosition.x += 0.1;
        break;
      case 40:
        //arrow bottom
        cameraPosition.z += 0.1;
        break;
      case 103:
        //numpad 7
        cameraPosition.y += 0.1;
        break;
      case 97:
        //numpad 1
        cameraPosition.y -= 0.1;
        break;
      case 100:
        //numpad 4
        cameraRotation.y += 0.01;
        break;
      case 102:
        //numpad 6
        cameraRotation.y -= 0.01;
        break;

      case 99:
        //numpad 3
        cameraRotation.z -= 0.01;
        break;
      case 105:
        //numpad 9
        cameraRotation.z += 0.01;
        break;

      case 104:
        //numpad 8
        cameraRotation.x -= 0.01;
        break;
      case 98:
        //numpad 2
        cameraRotation.x += 0.01;
        break;
    }
  });
}

let i = 0;
let positionVal = "";
let rotationVal = "";
let temp = "";
function renderCamera() {
  i++;
  camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
  camera.rotation.set(cameraRotation.x, cameraRotation.y, cameraRotation.z);
  requestAnimationFrame(renderCamera);
  renderer.render(scene, camera);
  if (i > 300) {
    //console.log(camera.position);
    //console.log(camera.rotation);

    document.getElementById("positionVal").value =
      cameraPosition.x + "," + cameraPosition.y + "," + cameraPosition.z;
    document.getElementById("rotationVal").value =
      camera.rotation.x + "," + camera.rotation.y + "," + camera.rotation.z;

    i = 0;
  }
}

renderCamera();

export { keysCamera };
