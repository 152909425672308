import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar,modelsLoaded } from "./progressbar";

const loadKeyboard2 = () => {
  const promiseKeyboard2 = new Promise((resolve, reject) => {
    loader.load(
      "office/model/keyboard.gltf",
      function (keyboard2) {
        scene.add(keyboard2.scene);

        const keyboard2Mesh = keyboard2.scene;

        keyboard2Mesh.position.set(0, 0, 0);
        keyboard2Mesh.rotation.x = 0;
        keyboard2Mesh.rotation.z = 0;
        keyboard2Mesh.position.x = 0;

        var geometry = new THREE.BoxGeometry(1, 1, 1);
        var material = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          transparent: true,
          opacity: 0,
        });
        var keyboard2Container = new THREE.Mesh(geometry, material);
        scene.add(keyboard2Container);
        keyboard2Container.add(keyboard2Mesh);
        keyboard2Mesh.position.set(0, 0, 0);
        keyboard2Container.position.set(
          4.288110146879134,1.6671517988782742,4.1256425379831745
        );
        keyboard2Container.rotation.set(
          3.038980663222523,-0.9341965878579271,1.8176139193471097
        );



        //ControlFunction(keyboard2Container); 
        resolve(keyboard2);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseKeyboard2;
};

export { loadKeyboard2 };
