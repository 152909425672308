import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar,modelsLoaded } from "./progressbar";

const loadHeadset = () => {
  const promiseHeadset = new Promise((resolve, reject) => {
    loader.load(
      "office/model/headset.gltf",
      function (headset) {
        scene.add(headset.scene);

        const headsetMesh = headset.scene;

        headsetMesh.position.set(0, 0, 0);
        headsetMesh.rotation.x = 0;
        headsetMesh.rotation.z = 0;
        headsetMesh.position.x = 0;

        headsetMesh.position.set(
          0.35561395934196033, 1.9281551469878697, 4.028070106556276
        );
        headsetMesh.rotation.set(
          0.014206990915977635,
          0.7632091518568211,
          0.03230919566970114);

        headsetMesh.scale.set(
          0.035,
          0.035,
          0.035
        );

        //ControlFunction(headsetMesh);
        resolve(headsetMesh);
        progressbar();

      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseHeadset;
};

export { loadHeadset };