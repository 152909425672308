import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const jpg = texture_loader.load("./office/textures/coding.jpg");
import { progressbar,modelsLoaded } from "./progressbar";

const loadCoding = () => {
  const promiseCoding = new Promise((resolve, reject) => {
    const materialFront = new THREE.MeshBasicMaterial({ map: jpg });
    const materialBlack = new THREE.MeshBasicMaterial({ color: 0x111111 });
    const materialOther = new THREE.MeshBasicMaterial({ color: 0xffffff });

    const cubeGeometry = new THREE.BoxGeometry(1, 1, 0.3);
    const mesh = new THREE.Mesh(cubeGeometry, [
      materialOther,
      materialBlack,
      materialOther,
      materialOther,
      materialFront,
      materialOther,
    ]);

    mesh.scale.x = 0.2;
    mesh.scale.y = 0.27;
    mesh.scale.z = 0.27;

    mesh.position.set( 1.6493308243337115,1.7874453263806183,4.243675824188946);
    mesh.rotation.set( -0.1130912669574169,-0.6410032007576953,-0.034601943226463885);
    
    scene.add(mesh);
    //ControlFunction(mesh);
    resolve(mesh);
    progressbar();
  });

  return promiseCoding;
};

export { loadCoding };
