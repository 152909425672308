import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const png = texture_loader.load("./office/textures/logodbWhite.png");
import { progressbar, modelsLoaded } from "./progressbar";

const loadDbLogoPngWhite = () => {
  const promiseDbPngWhite = new Promise((resolve, reject) => {
    var planeGeometry = new THREE.PlaneGeometry(1, 1);
    var planeMaterial = new THREE.MeshBasicMaterial({ color: 0x999999 });
    var mesh = new THREE.Mesh(planeGeometry, planeMaterial);

    const material = new THREE.MeshBasicMaterial({
      map: png,
      transparent: true,
    });

    mesh.material = material;

    mesh.scale.x = 0.3;
    mesh.scale.y = 0.1;

    mesh.position.set(
      0.7778978688920979,1.5039933948301885,5.292115161683562
    );
    mesh.rotation.set(
      0.30997226895294405,0.5640995193236686,-0.16165459902379548
    );

    scene.add(mesh);
    //ControlFunction(mesh);
    resolve(mesh);
    progressbar();
  });

  return promiseDbPngWhite;
};

export { loadDbLogoPngWhite };
