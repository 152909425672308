import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadPhp = () => {
  const promisePhp = new Promise((resolve, reject) => {
    loader.load(
      "office/model/php.gltf",
      function (stand) {
        scene.add(stand.scene);

        const phpMesh = stand.scene;

        phpMesh.position.set(
            4.180135377896686,0.5736470680329058,9.920643343702258

        );

        phpMesh.rotation.set(
          1.496115546819817,0.03132073860337906,3.1356390905168974
        );

        phpMesh.scale.set(9.9, 9.9, 9.9);
        //ControlFunction(phpMesh);
        resolve(phpMesh);

        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promisePhp;
};

export { loadPhp };
