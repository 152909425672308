import * as THREE from "three";
import { scene } from "./scene";
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
import { progressbar, modelsLoaded } from "./progressbar";
const wall = texture_loader.load("./office/textures/wall4.jpg");

const loadFrontWall = () => {
  const promiseFrontWall = new Promise((resolve, reject) => {
    const frontWallGeometry = new THREE.PlaneGeometry(2, 2);
    wall.wrapS = THREE.RepeatWrapping;
    wall.wrapT = THREE.RepeatWrapping;
    wall.magFilter = THREE.LinearFilter;
    wall.minFilter = THREE.LinearMipmapLinearFilter;
    //wall.repeat.set(2, 2);
    wall.repeat.set(1.1, 2);
    wall.castShadow = true;
    wall.receiveShadow = true;

    const materialFrontWallGeometry = new THREE.MeshBasicMaterial({
      map: wall,
    });
    const frontWall = new THREE.Mesh(
      frontWallGeometry,
      materialFrontWallGeometry
    );
    frontWall.material = materialFrontWallGeometry;

    frontWall.scale.x = 4.5;
    frontWall.scale.y = 3.5;

    frontWall.position.set(0,0.5258566186748896,2.9252479236173814);

    scene.add(frontWall);
    //ControlFunction(frontWall);
    resolve(frontWall);
    progressbar();
  });
  return promiseFrontWall;
};

export { loadFrontWall };
