import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let monitor_screenMesh = "";
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
let currentTexture = 1;
import { progressbar,modelsLoaded } from "./progressbar";

const loadCoinopScreen = () => {
  const promiseCoinopScreen = new Promise((resolve, reject) => {
    const geometry = new THREE.BoxGeometry();
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const cube = new THREE.Mesh(geometry, material);
    const screenTexture1 = texture_loader.load("./office/textures/coinop1.jpg");
    const screenTexture2 = texture_loader.load("./office/textures/coinop2.jpg");
    const screenTexture3 = texture_loader.load("./office/textures/coinop3.jpg");
    const screenTexture4 = texture_loader.load("./office/textures/coinop4.jpg");
    const materialScreen1 = new THREE.MeshBasicMaterial({
      map: screenTexture1,
    });
    const materialScreen2 = new THREE.MeshBasicMaterial({
      map: screenTexture2,
    });
    const materialScreen3 = new THREE.MeshBasicMaterial({
      map: screenTexture3,
    });
    const materialScreen4 = new THREE.MeshBasicMaterial({
      map: screenTexture4,
    });

    /*
    const materialScreen4 = new THREE.MeshBasicMaterial({
      map: screenTexture4,
    });
    */

    cube.material = materialScreen1;

    cube.position.set(-6.966709860320177,2.067513824297775,4.282805531315236);
    cube.rotation.set(
      -2.1909980254842707,
      1.4130172419593707,
      2.1897158319071313
    );

    cube.scale.x = 0.8;
    cube.scale.y = 0.8;
    cube.scale.z = 0.0001;

    scene.add(cube);

    //ControlFunction(cube);

    resolve(cube);
    progressbar();

    function changeTexture() {
      if (currentTexture == 1) {
        cube.material = materialScreen2;
        currentTexture = 2;
      } else if (currentTexture == 2) {
        cube.material = materialScreen3;
        currentTexture = 3;
      } else if (currentTexture == 3) {
        cube.material = materialScreen4;
        currentTexture = 4;
      } else if (currentTexture == 4) {
        cube.material = materialScreen1;
        currentTexture = 1;
      }
    }

    setInterval(changeTexture, 1000);
  });
  return promiseCoinopScreen;
};

export { loadCoinopScreen };
