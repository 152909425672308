import * as THREE from "three";
import { scene } from "./scene";
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
import { progressbar, modelsLoaded } from "./progressbar";
const wallDx = texture_loader.load("./office/textures/wall3.jpg");

const loadFrontWallDx = () => {
  const promiseFrontWallDx = new Promise((resolve, reject) => {
    const frontWallGeometryDx = new THREE.PlaneGeometry(2, 2);
    wallDx.wrapS = THREE.RepeatWrapping;
    wallDx.wrapT = THREE.RepeatWrapping;
    wallDx.magFilter = THREE.LinearFilter;
    wallDx.minFilter = THREE.LinearMipmapLinearFilter;
    wallDx.repeat.set(1, 2);
    wallDx.castShadow = true;
    wallDx.receiveShadow = true;

    const materialFrontWallGeometryDx = new THREE.MeshBasicMaterial({
      map: wallDx,
    });
    const frontWallDx = new THREE.Mesh(
      frontWallGeometryDx,
      materialFrontWallGeometryDx
    );
    frontWallDx.material = materialFrontWallGeometryDx;

    frontWallDx.scale.x = 0.5;
    frontWallDx.scale.y = 3.5;

    frontWallDx.position.set(
      3.110259727080698,
      0.5689439284456813,
      3.3546925507013423
    );

    var quaternion = new THREE.Quaternion();
    quaternion.setFromAxisAngle(new THREE.Vector3(0, 1, 0), -Math.PI / 2);

    // Applicazione della rotazione all'oggetto
    frontWallDx.rotation.setFromQuaternion(quaternion);

    scene.add(frontWallDx);
    //ControlFunction(frontWallDx);
    resolve(frontWallDx);
    progressbar();
  });
  return promiseFrontWallDx;
};

export { loadFrontWallDx };
