import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const pngTask = texture_loader.load("./office/textures/task.png");
import { progressbar, modelsLoaded } from "./progressbar";

const loadTask = () => {
  const promiseTask = new Promise((resolve, reject) => {
    var geometryTask = new THREE.PlaneGeometry(1, 1);
    var materialTask = new THREE.MeshBasicMaterial({ color: 0x999999 });
    var task = new THREE.Mesh(geometryTask, materialTask);
    const material = new THREE.MeshBasicMaterial({
      map: pngTask,
      transparent: true,
    });
    task.material = material;
    task.scale.x = 1;
    task.scale.y = 1.9;
    task.position.set(
      -3.905082753827242,
      2.065556933139232,
      3.8979607618500616
    );
    scene.add(task);
    //ControlFunction(task);
    resolve(task);
    progressbar();
  });

  return promiseTask;
};

export { loadTask };
