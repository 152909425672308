import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadGuitar = () => {
  const promiseGuitar = new Promise((resolve, reject) => {
    loader.load(
      "office/model/guitar.gltf",
      function (guitar) {
        scene.add(guitar.scene);
        const guitarMesh = guitar.scene;
        guitarMesh.position.set(
          7.2376533124125135,1.2528896081419945,7.6330037699400854);
        guitarMesh.rotation.set(
          0.2545263164973674,-1.504257581727214,0.25186113069013893
        );

        guitarMesh.scale.set(0.005, 0.005, 0.005);

        //ControlFunction(guitarMesh);

        guitarMesh.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = false;
            object.receiveShadow = false;
          }
        });

        resolve(guitarMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseGuitar;
};

export { loadGuitar };
