import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadReactLogo = () => {
  const promiseReactLogo = new Promise((resolve, reject) => {
    loader.load(
      "office/model/reactlogo.gltf",
      function (stand) {
        scene.add(stand.scene);

        const ReactLogoMesh = stand.scene;

        ReactLogoMesh.position.set(
          4.668741851877535,2.1819940933790094,9.920643343702258
        );

        ReactLogoMesh.rotation.set(
          -3.1233229765789563,0.03132073860337906,3.1356390905168974
        );

        ReactLogoMesh.scale.set(0.12, 0.12, 0.12);
        //ControlFunction(ReactLogoMesh);
        resolve(ReactLogoMesh);

        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseReactLogo;
};

export { loadReactLogo };
