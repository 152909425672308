import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadAir = () => {
  const promiseAir = new Promise((resolve, reject) => {
    loader.load(
      "office/model/air.gltf",
      function (air) {
        scene.add(air.scene);

        const airMesh = air.scene;

        airMesh.position.set(0, 0, 0);
        airMesh.rotation.x = 0;
        airMesh.rotation.z = 0;
        airMesh.position.x = 0;

        airMesh.position.set(
          -1.8960327448828587,2.44016816144109,3.0101159692973205
        );
        airMesh.rotation.set(
          0.191261072799067,-1.5167664340189313,0.2331187536735381
        );

        airMesh.scale.set(0.22, 0.22, 0.22);

        airMesh.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = false;
            object.receiveShadow = false;
          }
        });

        //ControlFunction(airMesh);
        resolve(airMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseAir;
};

export { loadAir };
