import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
import { progressbar,modelsLoaded } from "./progressbar";

const loadPc = () => {
  const promisePc = new Promise((resolve, reject) => {
    loader.load(
      "office/model/pc.gltf",
      function (pc) {
        scene.add(pc.scene);

        const pcMesh = pc.scene;

        pcMesh.scale.x = 1.4;
        pcMesh.scale.y = 1.4;
        pcMesh.scale.z = 1.4;

        pcMesh.position.set(0, 0, 0);

        pc.scene.traverse(function (node) {
          if (node.isMesh) {
            node.material.color.multiplyScalar(10);
            node.material.needsUpdate = true;
          }
        });

        var geometry = new THREE.BoxGeometry(1, 1, 1);
        var material = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          transparent: true,
          opacity: 0,
        });
        var pcGroup = new THREE.Mesh(geometry, material);
        scene.add(pcGroup);
        pcGroup.add(pcMesh);
        pcGroup.position.set(
          0.6333312249980556,
          1.55999999999906,
          3.7422910032563115
        );
        pcGroup.rotation.set(0, 0.2698349430982152, 0);

        resolve(pc);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promisePc;
};

export { loadPc };
