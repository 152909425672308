import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadXbox = () => {
  const promiseXbox = new Promise((resolve, reject) => {
    loader.load(
      "office/model/xbox.gltf",
      function (xbox) {
        scene.add(xbox.scene);

        const xboxMesh = xbox.scene;

        var material = new THREE.MeshBasicMaterial({ color: 0xff0000 });

        xboxMesh.material = material;

        xboxMesh.position.set(
          -7.091095929834339,0.9502048356229562,7.004553472129464
        );
        xboxMesh.rotation.set(0, 1.5615444947475798, 0);
        xboxMesh.scale.set(3.1, 2.6, 2.6);

        //ControlFunction(xboxMesh);
        resolve(xboxMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseXbox;
};

export { loadXbox };
