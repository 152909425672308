import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadSofa = () => {
  const promiseSofa = new Promise((resolve, reject) => {
    loader.load(
      "office/model/sofa.gltf",
      function (sofa) {
        scene.add(sofa.scene);

        const sofaMesh = sofa.scene;
        sofaMesh.position.set(
          -5.4446969935977005,0.4802299245773882,7.693373146515083
        );
        sofaMesh.rotation.set(
          0.010266157812517385,-0.0030182807931994874,0.04216102514499943
        );
          
        sofaMesh.scale.set(1, 1, 1);

        sofaMesh.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = false;
          }
        });

        //ControlFunction(sofaMesh);
        resolve(sofaMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseSofa;
};

export { loadSofa };
