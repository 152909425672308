import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { loadAir } from "./office/air";
import { loadBackWall } from "./office/backWall";
import { camera } from "./office/camera";
import { loadChair } from "./office/chair";
import { loadCity } from "./office/city";
import { loadCodeWall1 } from "./office/codeWall1";
import { loadHtml5 } from "./office/html5";
import { loadJoypad2 } from "./office/joypad2";
import { loadJs } from "./office/js";
import { loadMonitor } from "./office/monitor";
import { loadNeon } from "./office/neon";
import { loadNodeLogo } from "./office/nodeLogo";
import { loadOffice } from "./office/office";
import { loadPhp } from "./office/php";
import { loadPs4 } from "./office/ps4";
import { loadPs5 } from "./office/ps5";
//import { ControlFunction } from "./office/controlFunction";
import { loadReactLogo } from "./office/reactLogo";
import { renderer as initialRenderer } from "./office/renderer";
import { scene } from "./office/scene";
import { loadSwitch } from "./office/switch";
import { loadUrl } from "./office/url";
import { loadVr } from "./office/vr";
var texture_loader = new THREE.TextureLoader();
let shadow = true;
const shadowValue = localStorage.getItem("shadow");
if (shadowValue !== null) {
  if (shadowValue === "true") {
    shadow = true;
  } else {
    shadow = false;
  }
}
//import { loadCodeWall2 } from "./office/codeWall2";
import { loadCodeWall3 } from "./office/codeWall3";
//import { loadCodeWall4 } from "./office/codeWall4";
import { loadCodeWall5 } from "./office/codeWall5";
import { loadCodeWall6 } from "./office/codeWall6";
import { loadCoffee } from "./office/coffee";
import { loadCoinop } from "./office/coinop";
import { loadCoinopScreen } from "./office/coinop_screen";
import { loadDoor } from "./office/door";
import { loadElix } from "./office/elix";
import { loadFan } from "./office/fan";
import { loadFrontWall } from "./office/frontWall";
import { loadFrontWallDx } from "./office/frontWallDx";
import { loadFrontWallSx } from "./office/frontWallSx";
import { loadGuitar } from "./office/guitar";
import { loadHeadset } from "./office/headset";
import { loadJava } from "./office/java";
import { loadJoypad3b } from "./office/joypad3b";
import { loadKeyboard } from "./office/keyboard";
import { loadKeyboard2 } from "./office/keyboard2";
import { loadPaint1 } from "./office/paint1";
import { loadPaint2 } from "./office/paint2";
import { loadPaint3 } from "./office/paint3";
import { loadPaint4 } from "./office/paint4";
import { loadPc } from "./office/pc";
import { loadPendrive } from "./office/pendrive";
import { loadRoof } from "./office/roof";
import { loadScreen } from "./office/screen";
import { loadShelf } from "./office/shelf";
import { loadSkills } from "./office/skills";
import { loadSmartphone } from "./office/smartphone";
import { loadSofa } from "./office/sofa";
import { loadStand } from "./office/stand";
import { loadTapisRoulant } from "./office/tapis-roulant";
import { loadWorld } from "./office/world";
//import { loadBeam } from "./office/beam";
import { loadNotebook } from "./office/notebook";
import { loadScreenNotebook } from "./office/screenNotebook";
import { loadTv } from "./office/tv";
//import { loadTvstand2 } from "./office/tvstand2";
import { loadCoding } from "./office/coding";
import { loadDbLogo } from "./office/dblogo";
import { loadDbLogoPng } from "./office/dblogoPng";
import { loadDbLogoPngWhite } from "./office/dblogoPngWhite";
import { loadGlass } from "./office/glass";
import { loadSeriesX } from "./office/seriesx";
import { loadSky } from "./office/sky";
import { loadTask } from "./office/task";
import { loadXbox } from "./office/xbox";
//import gsap from "gsap";
import { clock } from "./office/clock";
import { keysCamera } from "./office/keysCamera";
import { StartTimeline } from "./office/timeline";
const loader = new GLTFLoader();
const elapsedTime = clock.getElapsedTime();

let modelPromises = [];

let renderer;

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

//config
const startTimeline = false;
const startKeysCamera = true;
const inputCamera = false;

if (!inputCamera) {
  document.querySelector(".positionDiv").style.display = "none";
}

//office
modelPromises.push(loadOffice());

//sofa
modelPromises.push(loadSofa());

//monitor
modelPromises.push(loadMonitor());

//react logo
modelPromises.push(loadReactLogo());

//node logo
modelPromises.push(loadNodeLogo());

//php
modelPromises.push(loadPhp());

//url
modelPromises.push(loadUrl());

//js
modelPromises.push(loadJs());

//html5
modelPromises.push(loadHtml5());

//joypad
modelPromises.push(loadJoypad2());
//modelPromises.push(loadJoypad3());
modelPromises.push(loadJoypad3b());

//chair
modelPromises.push(loadChair());

//ps4
modelPromises.push(loadPs4());

//ps5
modelPromises.push(loadPs5());

//guitar
modelPromises.push(loadGuitar());

//coinop
modelPromises.push(loadCoinop());
modelPromises.push(loadCoinopScreen());

//vr
modelPromises.push(loadVr());

//headset
modelPromises.push(loadHeadset());

//pendrive
modelPromises.push(loadPendrive());

//air
modelPromises.push(loadAir());

//screen
modelPromises.push(loadScreen());

//stand
modelPromises.push(loadStand());

//door
modelPromises.push(loadDoor());

//world
modelPromises.push(loadWorld());

//shelf
modelPromises.push(loadShelf());

//keyboard
modelPromises.push(loadKeyboard());
modelPromises.push(loadKeyboard2());

//motherboard
//modelPromises.push(loadMotherboard());

//frontWall
modelPromises.push(loadFrontWall());
modelPromises.push(loadFrontWallSx());
modelPromises.push(loadFrontWallDx());

//backWall
modelPromises.push(loadBackWall());

//roof
modelPromises.push(loadRoof());

//city
modelPromises.push(loadCity());

//glass
modelPromises.push(loadGlass());

//codeWall
modelPromises.push(loadCodeWall1());
//modelPromises.push(loadCodeWall2());
modelPromises.push(loadCodeWall3());
//modelPromises.push(loadCodeWall4());
modelPromises.push(loadCodeWall5());
modelPromises.push(loadCodeWall6());

//fan
modelPromises.push(loadFan());

//db logo
modelPromises.push(loadDbLogo());

//db logo png white
modelPromises.push(loadDbLogoPngWhite());

//db logo png
modelPromises.push(loadDbLogoPng());

//java
modelPromises.push(loadJava());

//skills
modelPromises.push(loadSkills());

//pc
modelPromises.push(loadPc());

//beam
//modelPromises.push(loadBeam());

//notebook
modelPromises.push(loadNotebook());

//screenNotebook
modelPromises.push(loadScreenNotebook());

//smartphone
modelPromises.push(loadSmartphone());

//paint1
modelPromises.push(loadPaint1());

//paint2
modelPromises.push(loadPaint2());

//paint3
modelPromises.push(loadPaint3());

//paint4
modelPromises.push(loadPaint4());

//tvstand
modelPromises.push(loadTv());

//xbox
modelPromises.push(loadXbox());

//seriesx
modelPromises.push(loadSeriesX());

//switch
modelPromises.push(loadSwitch());

//neon
modelPromises.push(loadNeon());

//coffee
modelPromises.push(loadCoffee());

const Fan = new THREE.Group();

//elix
modelPromises.push(loadElix());

//sky
modelPromises.push(loadSky());

//asphalt
//modelPromises.push(loadAsphalt());

//floor
//modelPromises.push(loadFloor());

///////////////////////////////////////////////////////

//tapisRoulant
modelPromises.push(loadTapisRoulant());

//task
modelPromises.push(loadTask());

//coding
modelPromises.push(loadCoding());

init();

if (startTimeline) {
  StartTimeline();
}

Promise.all(modelPromises)
  .then(() => {
    console.log("Models loaded");
    document.querySelector("div.webglHover").style.display = "none";
  })
  .catch((err) => {
    console.log(err);
  });
/*
window.myApp = {
  renderer: renderer,
};
*/

function init() {
  renderer = initialRenderer;
  renderer.setSize(window.innerWidth, window.innerHeight);

  //renderer.outputEncoding = THREE.sRGBEncoding;
  //renderer.outputEncoding = THREE.LinearEncoding;
  //renderer.outputEncoding = THREE.GammaEncoding;
  //renderer.outputEncoding = THREE.RGBEEncoding;
  //renderer.outputEncoding = THREE.LogLuvEncoding;
  //renderer.physicallyCorrectLights = true;

  document.body.appendChild(renderer.domElement);

  //light 1
  const lightContainerGeometry = new THREE.BoxGeometry(1, 1, 1);
  const lightContainerMaterial = new THREE.MeshBasicMaterial({
    color: 0xcdcdcd,
  });
  const lightContainer = new THREE.Mesh(
    lightContainerGeometry,
    lightContainerMaterial
  );
  lightContainer.position.set(
    -2.3615650600385356,
    3.2119737851728565,
    4.497990270406487
  );
  lightContainer.rotation.set(
    1.7800172146832778,
    -1.2549971047050765,
    -2.1300213748784094
  );

  const lightContainerMaterialB = new THREE.MeshPhysicalMaterial({
    transparent: true,
    opacity: 0,
  });

  lightContainer.material = lightContainerMaterialB;
  scene.add(lightContainer);

  const lightContainerGeometryt = new THREE.BoxGeometry(1, 1, 1);
  const lightContainerMaterialt = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0,
  });
  const lightTarget = new THREE.Mesh(
    lightContainerGeometryt,
    lightContainerMaterialt
  );
  lightTarget.position.set(
    -2.6142609827063943,
    1.6032648172729573,
    -2.344707021496216
  );
  lightTarget.rotation.set(
    1.7800172146832778,
    -1.2549971047050765,
    -2.1300213748784094
  );
  scene.add(lightTarget);

  const light = new THREE.DirectionalLight(0x999999);
  light.position.set(0, 0, 0);
  light.castShadow = true;
  light.intensity = 4;

  light.shadow.enabled = true;
  light.shadow.mapSize.width = 512;
  light.shadow.mapSize.height = 512;
  light.shadow.bias = 0.0001;
  scene.add(light);

  //Floor
  const parquet = texture_loader.load("./office/textures/parquet.jpg");
  var floorGeometry = new THREE.PlaneGeometry(2, 2);
  var floorMaterial = new THREE.MeshLambertMaterial({ map: parquet });
  var floor = new THREE.Mesh(floorGeometry, floorMaterial);
  floor.receiveShadow = true;
  parquet.wrapS = THREE.RepeatWrapping;
  parquet.wrapT = THREE.RepeatWrapping;
  parquet.magFilter = THREE.LinearFilter;
  parquet.minFilter = THREE.LinearMipmapLinearFilter;
  parquet.repeat.set(8, 4);
  floorMaterial.receiveShadow = true;
  scene.add(floor);
  floor.scale.x = 7.8;
  floor.scale.y = 3.8;
  floor.position.set(0, 0.5698188312331918, 6.581996091425584);
  floor.rotation.set(-1.5628952655007182, 0, 0);

  /*
  const floorReflector = new Reflector(floorGeometry, {
    clipBias: 0.003,
    textureWidth: window.innerWidth * window.devicePixelRatio,
    textureHeight: window.innerHeight * window.devicePixelRatio,
    color: 0x888888
});
  floorReflector.rotation.x = -Math.PI / 2;
  scene.add(floorReflector);
*/

  //ControlFunction(floor);
  //end floor

  ///////////////////////////////////

  lightContainer.add(light);
  light.target = lightTarget;
  //ControlFunction(lightTarget);
  //ControlFunction(lightContainer);

  light.lookAt(floor.position);
  //end light1

  //light2
  const lightContainerGeometry2 = new THREE.BoxGeometry(1, 1, 1);
  const lightContainerMaterial2 = new THREE.MeshBasicMaterial({
    color: 0xffffff,
  });
  const lightContainer2 = new THREE.Mesh(
    lightContainerGeometry2,
    lightContainerMaterial2
  );

  lightContainer2.position.set(
    2.1814686243449376,
    2.0119737851728565,
    2.6872926372793575
  );

  lightContainer2.rotation.set(
    1.7800172146832778,
    1.2549971047050765,
    2.1300213748784094
  );


  /*
  
  lightContainer2.position.set(
    1.1814686243449376,
    1.9119737851728565,
    3.1872926372793575
  );

  lightContainer2.rotation.set(
    1.7800172146832778,
    1.2549971047050765,
    2.1300213748784094
  );
  
  */

  const lightContainerMaterialC = new THREE.MeshPhysicalMaterial({
    transparent: true,
    opacity: 0,
  });

  lightContainer2.material = lightContainerMaterialC;
  scene.add(lightContainer2);
  const light2 = new THREE.DirectionalLight(0xffffff);
  light2.position.set(0, 0, 0);
  light2.castShadow = true;
  light2.intensity = 1.5;
  light2.distance = 50;
  scene.add(light2);
  lightContainer2.add(light2);

  const lightContainerGeometry2t = new THREE.BoxGeometry(1, 1, 1);
  const lightContainerMaterial2t = new THREE.MeshBasicMaterial({
    color: 0x999999,
  });
  const light2Target = new THREE.Mesh(
    lightContainerGeometry2t,
    lightContainerMaterial2t
  );
  light2Target.position.set(
    1.2397613209875253,
    0.00507372351578934,
    2.6839062581295945
  );
  /*
   light2Target.position.set(
    1.2397613209875253,
    0.00507372351578934,
    2.6839062581295945
  );
  */
  light2Target.rotation.set(
    1.5800172146832778,
    -1.2549971047050765,
    -2.1300213748784094
  );

  const lightTargetMaterial = new THREE.MeshPhysicalMaterial({
    transparent: true,
    opacity: 0,
  });

  light2Target.material = lightTargetMaterial;
  scene.add(light2Target);
  light2.target = light2Target;
  //ControlFunction(lightContainer2);
  //ControlFunction(light2Target);
  //end light2

  //light3
  const lightContainerGeometry3 = new THREE.BoxGeometry(1, 1, 1);
  const lightContainerMaterial3 = new THREE.MeshBasicMaterial({
    color: 0x000000,
  });
  const lightContainer3 = new THREE.Mesh(
    lightContainerGeometry3,
    lightContainerMaterial3
  );
  lightContainer3.position.set(
    2.3581612556219342,
    4.2119737851728565,
    4.497990270406487
  );
  lightContainer3.rotation.set(
    1.7800172146832778,
    -1.2549971047050765,
    -2.1300213748784094
  );

  const lightContainerMaterialC3 = new THREE.MeshPhysicalMaterial({
    transparent: true,
    opacity: 0,
  });

  lightContainer3.material = lightContainerMaterialC3;
  scene.add(lightContainer3);
  const light3 = new THREE.DirectionalLight(0xffffff);
  light3.position.set(0, 0, 0);
  light3.castShadow = true;
  light3.intensity = 2;
  scene.add(light3);
  lightContainer3.add(light3);

  const lightContainerGeometry3t = new THREE.BoxGeometry(1, 1, 1);
  const lightContainerMaterial3t = new THREE.MeshBasicMaterial({
    color: 0x999999,
  });
  const light3Target = new THREE.Mesh(
    lightContainerGeometry3t,
    lightContainerMaterial3t
  );
  light3Target.position.set(
    3.9940844277753516,
    5.8726592163331047,
    8.248717817819486
  );
  light3Target.rotation.set(
    1.7800172146832778,
    -1.2549971047050765,
    -2.1300213748784094
  );

  const lightTargetMaterial3 = new THREE.MeshPhysicalMaterial({
    transparent: true,
    opacity: 0,
  });

  light3Target.material = lightTargetMaterial3;
  scene.add(light3Target);
  light3.target = light3Target;
  //ControlFunction(lightContainer3);
  //ControlFunction(light3Target);
  //end light3












  //light4
  const lightContainerGeometry4 = new THREE.BoxGeometry(1, 1, 1);
  const lightContainerMaterial4 = new THREE.MeshBasicMaterial({
    color: 0x000000,
  });
  const lightContainer4 = new THREE.Mesh(
    lightContainerGeometry4,
    lightContainerMaterial4
  );
  lightContainer4.position.set(
    2.3581612556219342,
    4.2119737851728565,
    4.497990270406487
  );
  lightContainer4.rotation.set(
    1.7800172146832778,
    -1.2549971047050765,
    -2.1300213748784094
  );

  const lightContainerMaterialC4 = new THREE.MeshPhysicalMaterial({
    transparent: true,
    opacity: 0,
  });

  lightContainer4.material = lightContainerMaterialC4;
  scene.add(lightContainer4);
  const light4 = new THREE.DirectionalLight(0xffffff);
  light4.position.set(0, 0, 0);
  light4.castShadow = false;
  light4.intensity = 3;
  scene.add(light4);
  lightContainer4.add(light4);

  const lightContainerGeometry4t = new THREE.BoxGeometry(1, 1, 1);
  const lightContainerMaterial4t = new THREE.MeshBasicMaterial({
    color: 0x999999,
  });
  const light4Target = new THREE.Mesh(
    lightContainerGeometry4t,
    lightContainerMaterial4t
  );
  light4Target.position.set(
    0.9940844277753516,
    5.8726592163331047,
    8.248717817819486
  );
  light4Target.rotation.set(
    1.7800172146832778,
    -1.2549971047050765,
    -2.1300213748784094
  );

  const lightTargetMaterial4 = new THREE.MeshPhysicalMaterial({
    transparent: true,
    opacity: 0,
  });

  light4Target.material = lightTargetMaterial4;
  scene.add(light4Target);
  light4.target = light4Target;
  //ControlFunction(lightContainer4);
  //ControlFunction(light4Target);
  //end light4















  if (startKeysCamera) {
    keysCamera();
  }
}

const sizes = {
  width: 800,
  height: 600,
};

window.addEventListener("resize", () => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();
  renderer.setSize(sizes.width, sizes.height);
});
