import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const pngUrl = texture_loader.load("./office/textures/url.png");
import { progressbar, modelsLoaded } from "./progressbar";

const loadUrl = () => {
  const promiseUrl = new Promise((resolve, reject) => {
    var geometryTask = new THREE.PlaneGeometry(1, 1);
    var materialTask = new THREE.MeshBasicMaterial({ color: 0x999999 });
    var url = new THREE.Mesh(geometryTask, materialTask);
    const material = new THREE.MeshBasicMaterial({
      map: pngUrl,
      transparent: true,
    });
    url.material = material;
    url.scale.x = 1.3;
    url.scale.y = 1.3;
    url.scale.z = 1.3;
    url.position.set(
      4.61795516824957,1.499811215504324,9.717219561162768
    );



    url.rotateY(Math.PI);


    
    scene.add(url);
    //ControlFunction(url);
    resolve(url);
    progressbar();
  });

  return promiseUrl;
};

export { loadUrl };
