import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
import { progressbar, modelsLoaded } from "./progressbar";
const loader = new GLTFLoader();

const loadSmartphone = () => {
  const promiseSmartphone = new Promise((resolve, reject) => {
    loader.load(
      "office/model/smartphone.gltf",
      function (smartphone) {
        scene.add(smartphone.scene);

        const smartphoneMesh = smartphone.scene;
        smartphone.scene.traverse(function (node) {
          if (node.isMesh) {
            node.material.reflectivity = 0;
            node.material.envMap = null;
            // node.castShadow = true;
            // node.receiveShadow = true;
          }
        });

        smartphoneMesh.scale.x = 0.17;
        smartphoneMesh.scale.y = 0.17;
        smartphoneMesh.scale.z = 0.17;
        smartphoneMesh.position.set(
          -0.4750291436273495,
          1.5539453087413055,
          4.6288238334243905
        );
        smartphoneMesh.rotation.set(
          3.1291213028687053,
          -0.21900939803538855,
          -3.129785507995702
        );
        //ControlFunction(smartphoneMesh);
        resolve(smartphone);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseSmartphone;
};

export { loadSmartphone };
