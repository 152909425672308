import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadMonitor = () => {
  const promiseMonitor = new Promise((resolve, reject) => {
    loader.load(
      "office/model/monitor.gltf",
      function (monitor) {
        scene.add(monitor.scene);

        const monitorMesh = monitor.scene;

        monitorMesh.position.set(0, 0, 0);
        monitorMesh.rotation.x = 0;
        monitorMesh.rotation.z = 0;
        monitorMesh.position.x = 0;

        monitorMesh.castShadow = true;
        //monitorMesh.receiveShadow = true;

        var geometry = new THREE.BoxGeometry(1, 1, 1);
        var material = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          transparent: true,
          opacity: 0,
        });
        var monitorContainer = new THREE.Mesh(geometry, material);
        scene.add(monitorContainer);
        monitorContainer.add(monitorMesh);
        monitorMesh.position.set(0, 0, 0);
        monitorContainer.position.set(
          -0.0005290475427340291,
          1.9299673013892478,
          4.136554596473941
        );
        monitorContainer.rotation.set(
          -0.22926447136786077,
          0.21035010703600662,
          0.05159614022550984
        );
        resolve(monitor);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseMonitor;
};

export { loadMonitor };
