import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
import { progressbar,modelsLoaded } from "./progressbar";

const loadFan = () => {
  const promiseFan = new Promise((resolve, reject) => {
    loader.load(
      "office/model/fan1.gltf",
      function (fan1) {
        scene.add(fan1.scene);
        resolve(fan1);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseFan;
};

export { loadFan };
