import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
import { progressbar, modelsLoaded } from "./progressbar";
const loader = new GLTFLoader();

const loadNotebook = () => {
  const promiseNotebook = new Promise((resolve, reject) => {
    loader.load(
      "office/model/notebook.gltf",
      function (notebook) {
        scene.add(notebook.scene);

        const notebookMesh = notebook.scene;

        notebookMesh.rotation.x = 0;
        notebookMesh.rotation.z = 0;
        notebookMesh.position.x = 0;

        notebookMesh.scale.x = 0.7;
        notebookMesh.scale.y = 0.7;
        notebookMesh.scale.z = 0.7;

        notebookMesh.position.set(
          -0.7156899985868602,1.5711286614541249,4.189597373940453
        );
        notebookMesh.rotation.set(
          3.1157186735597753,-0.8604006004826108,3.101488395668169
        );
        
        //ControlFunction(notebookMesh);
        resolve(notebook);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseNotebook;
};

export { loadNotebook };
