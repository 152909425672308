import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as TWEEN from "tween.js";
import { renderer } from "./renderer";

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

let orbitControlsEnabled = false;
let h = window.innerHeight;
let perspective = 65;
if (document.getElementById("heightScene")) {
  h = document.getElementById("heightScene").value;
  perspective = 10;
}

if (isMobile) {
  perspective = 80;
}

let camera = new THREE.PerspectiveCamera(
  perspective,
  window.innerWidth / h,
  0.1,
  1000
);

let controls = new OrbitControls(camera, renderer.domElement);
controls.enableDamping = true;
controls.dampingFactor = 0.25;
controls.screenSpacePanning = false;
controls.minPolarAngle = Math.PI / 2.5;
controls.maxPolarAngle = 1.4;
controls.minAzimuthAngle = -Math.PI / 10;
controls.maxAzimuthAngle = Math.PI / 10;
controls.minDistance = 6;
controls.maxDistance = 11;

function animateTween(targetPosition, targetRotation, duration) {
  var tweenPosition = new TWEEN.Tween(camera.position)
    .to(targetPosition, duration)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(() => {
      //controls.update();
    })
    .start();

  var tweenRotationX = new TWEEN.Tween(camera.rotation)
    .to({ x: targetRotation.x }, duration)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(() => {
      //controls.update();
    })
    .start();

  var tweenRotationY = new TWEEN.Tween(camera.rotation)
    .to({ y: targetRotation.y }, duration)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(() => {
      //controls.update();
    })
    .start();

  var tweenRotationZ = new TWEEN.Tween(camera.rotation)
    .to({ z: targetRotation.z }, duration)
    .onUpdate(() => {
      //controls.update();
    })
    .easing(TWEEN.Easing.Quadratic.InOut)
    .start();
}

let targetPosition = new THREE.Vector3(0.3, 2.4, 8);
let targetRotation = new THREE.Euler(-0.22, 0.11, 0.01, "XYZ");

const animateCamera = (num) => {
  if (num === 1) {
    orbitControlsEnabled = true;
  } else {
    orbitControlsEnabled = false;
  }
  if (num === 1) {
    targetPosition = new THREE.Vector3(0.3, 2.4, 8);
    targetRotation = new THREE.Euler(-0.22, 0.11, 0.01, "XYZ");
  } else if (num === 2) {
    targetPosition = new THREE.Vector3(
      0.20338780598909975,
      2.2999995156929023,
      5.201068408204371
    );
    targetRotation = new THREE.Euler(
      -0.5900715098628999,
      0.2000027501053455,
      0.1300003532214859,
      "XYZ"
    );
  } else if (num === 3) {
    targetPosition = new THREE.Vector3(
      -3.4971645746992137,
      2.4002658000001293,
      6.80186097971378
    );
    targetRotation = new THREE.Euler(
      -0.13995388354763824,
      0.10837520357149155,
      0.009838118941158602,
      "XYZ"
    );
  } else if (num === 4) {
    targetPosition = new THREE.Vector3(
      -2.5999981885279326,
      2.100000169825506,
      6.400001188778546
    );
    targetRotation = new THREE.Euler(
      -0.27203236512516393,
      1.6853840337530832,
      0.26293742253275165,
      "XYZ"
    );
  } else if (num === 5) {
    targetPosition = new THREE.Vector3(
      -2.0999981885279326,
      2.3000001698255064,
      5.20000118877855
    );
    targetRotation = new THREE.Euler(
      0.08796763487483616,
      2.3753840337530754,
      -0.09706257746724842739999,
      "XYZ"
    );
  } else if (num === 6) {
    targetPosition = new THREE.Vector3(
      -6.499998188527925,
      2.4000001698255065,
      5.000001188778551
    );
    targetRotation = new THREE.Euler(
      0.10796763487483615,
      3.8153840337530447,
      0.04293742253275157,
      "XYZ"
    );
  } else if (num === 7) {
    targetPosition = new THREE.Vector3(
      1.7997201385904888,
      2.0999999996083583,
      7.599533568538504
    );
    targetRotation = new THREE.Euler(
      -0.2914748981017404,
      -1.3769481623709914,
      -0.2997371765155623,
      "XYZ"
    );
  } else if (num === 8) {
    targetPosition = new THREE.Vector3(
      4.39972013859049,
      2.0999999996083583,
      5.99953356853851
    );
    targetRotation = new THREE.Euler(
      -0.2914748981017404,
      -0.7269481623709908,
      -0.20973717651556223,
      "XYZ"
    );
  } else if (num === 9) {
    targetPosition = new THREE.Vector3(
      3.4990523399912515,
      2.2992418719929995,
      4.830806239976685
    );
    targetRotation = new THREE.Euler(
      0.028543205522133082,
      -3.1540969489547366,
      0.01076818523552396,
      "XYZ"
    );
  } else if (num === 10) {
    targetPosition = new THREE.Vector3(
      3.5499052339991275,
      2.0992418719929993,
      5.930806239976681
    );
    targetRotation = new THREE.Euler(
      0.008543205522133073,
      -2.7640969489547444,
      -0.009231814764476052,
      "XYZ"
    );
  } else if (num === 11) {
    targetPosition = new THREE.Vector3(
      3.6499052339991276,
      2.0992418719929993,
      9.630806239976668
    );
    targetRotation = new THREE.Euler(
      0.008543205522133073,
      -5.714096948954682,
      -0.009231814764476052,
      "XYZ"
    );
  } else if (num === 12) {
    targetPosition = new THREE.Vector3(
      7.1499052339991165,
      2.0992418719929993,
      3.9308062399766874
    );
    targetRotation = new THREE.Euler(
      0.008543205522133073,
      -5.834096948954679,
      -0.009231814764476052,
      "XYZ"
    );
  }

  var duration = 1000;
  animateTween(targetPosition, targetRotation, duration);

  if (orbitControlsEnabled) {
    controls.enableZoom = true;
    controls.enableRotate = true;
    controls.enablePan = true;
  } else {
    controls.enableZoom = false;
    controls.enableRotate = false;
    controls.enablePan = false;
  }
};

function animate() {
  requestAnimationFrame(animate);
  TWEEN.update();
}

animate();

if (document.getElementById("heightScene")) {
  camera.position.set(
    0.10000000000000007,
    4.399999999999998,
    12.09999999999999
  );
  camera.rotation.set(
    -0.3471738338182195,
    0.008974236055871481,
    0.043451569215224424
  );
} else {
  camera.position.set(0.3, 2.4, 8);
  camera.rotation.x = -0.22;

  window.addEventListener("load", function () {
    const buttonCamera1 = document.getElementById("buttonCamera1");
    buttonCamera1.addEventListener("click", function () {
      animateCamera(1);
    });

    const buttonCamera2 = document.getElementById("buttonCamera2");
    buttonCamera2.addEventListener("click", function () {
      animateCamera(2);
    });

    const buttonCamera3 = document.getElementById("buttonCamera3");
    buttonCamera3.addEventListener("click", function () {
      animateCamera(3);
    });

    const buttonCamera4 = document.getElementById("buttonCamera4");
    buttonCamera4.addEventListener("click", function () {
      animateCamera(4);
    });

    const buttonCamera5 = document.getElementById("buttonCamera5");
    buttonCamera5.addEventListener("click", function () {
      animateCamera(5);
    });

    const buttonCamera6 = document.getElementById("buttonCamera6");
    buttonCamera6.addEventListener("click", function () {
      animateCamera(6);
    });

    const buttonCamera7 = document.getElementById("buttonCamera7");
    buttonCamera7.addEventListener("click", function () {
      animateCamera(7);
    });

    const buttonCamera8 = document.getElementById("buttonCamera8");
    buttonCamera8.addEventListener("click", function () {
      animateCamera(8);
    });

    const buttonCamera9 = document.getElementById("buttonCamera9");
    buttonCamera9.addEventListener("click", function () {
      animateCamera(9);
    });

    const buttonCamera10 = document.getElementById("buttonCamera10");
    buttonCamera10.addEventListener("click", function () {
      animateCamera(10);
    });

    const buttonCamera11 = document.getElementById("buttonCamera11");
    buttonCamera11.addEventListener("click", function () {
      animateCamera(11);
    });

    const buttonCamera12 = document.getElementById("buttonCamera12");
    buttonCamera12.addEventListener("click", function () {
      animateCamera(12);
    });
  });
}

export { camera };
