import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
const loader = new GLTFLoader();
let texture_loader = new THREE.TextureLoader();
import { ControlFunction } from "./controlFunction";
const pngCodeWall3 = texture_loader.load("./office/textures/codeWall6.jpg");
import { progressbar, modelsLoaded } from "./progressbar";

const loadCodeWall3 = () => {
  const promiseCodeWall3 = new Promise((resolve, reject) => {
    const geometryCodeWall3 = new THREE.PlaneGeometry(1, 1);
    const materialCodeWall3 = new THREE.MeshBasicMaterial({
      map: pngCodeWall3,
      transparent: true,
    });
    const codeWall3 = new THREE.Mesh(geometryCodeWall3, materialCodeWall3);

    codeWall3.scale.x = 0.8;
    codeWall3.scale.y = 1;

    codeWall3.position.set(
      1.5003889128444426,2.9905960510486387,3.1009359829386775
    );

    scene.add(codeWall3);
    //ControlFunction(codeWall3);

    resolve(codeWall3);
    progressbar();
  });

  return promiseCodeWall3;
};

export { loadCodeWall3 };
