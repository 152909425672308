import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar,modelsLoaded } from "./progressbar";

const loadDoor = () => {
  const promiseDoor = new Promise((resolve, reject) => {
    loader.load(
      "office/model/door.gltf",
      function (door) {
        scene.add(door.scene);
        const doorMesh = door.scene;
        var materialDoor = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        doorMesh.material = materialDoor;
        doorMesh.position.set(
          0.03499959131995545,0.5418489792520864,10.069743196035015
        );
        var rotationAngle = Math.PI / 2; 
        doorMesh.rotation.set(0, rotationAngle, 0);
          doorMesh.scale.set(
          0.9,
          0.9,
          0.9
        );
        
        //ControlFunction(doorMesh);
        resolve(doorMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseDoor;
};

export { loadDoor };