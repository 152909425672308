import * as THREE from "three";
import { scene } from "./scene";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
const loader = new GLTFLoader();
import { progressbar, modelsLoaded } from "./progressbar";

const loadChair = () => {
  const promiseChair = new Promise((resolve, reject) => {
    loader.load(
      "office/model/chair.gltf",
      function (Chair) {
        scene.add(Chair.scene);

        const ChairMesh = Chair.scene;

        ChairMesh.position.set(
          0.5952603276999258,
          0.5825735517233095,
          4.982076370722194
        );
        ChairMesh.rotation.set(
          3.1224088478419754,
          1.0060896251801674,
          -3.0832566107661
        );

        ChairMesh.scale.set(1, 1, 1);

        ChairMesh.traverse(function (object) {
          if (object.isMesh) {
            object.castShadow = true;
            object.receiveShadow = false;
          }
        });

        //ControlFunction(ChairMesh);
        resolve(ChairMesh);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseChair;
};

export { loadChair };
