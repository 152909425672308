import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { ControlFunction } from "./controlFunction";
import { progressbar } from "./progressbar";
import { scene } from "./scene";
const loader = new GLTFLoader();

const loadSkills = () => {
  const promiseSkills = new Promise((resolve, reject) => {
    loader.load(
      "office/model/skills.gltf",
      function (logo) {
        scene.add(logo.scene);

        const logoMesh = logo.scene;

     
        logoMesh.scale.x = 0.35;
        logoMesh.scale.y = 0.35;
        logoMesh.scale.z = 0.35;

        logoMesh.position.set(
          4.6304766222538145,2.5422917845789867,9.91460791886126
        );

        logoMesh.rotation.set(
          1.581457158041909,
          -0.00040016120876790895,
          3.104076040061552
        );

        //ControlFunction(logoMesh);
        resolve(logo);
        progressbar();
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );
  });
  return promiseSkills;
};

export { loadSkills };
